import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import GroupsImg from "../../assets/images/people-centric/groups.webp"
import GroupsMImg from "../../assets/images/people-centric/groups-m.webp"
import PeopleCentricMImg from "../../assets/images/people-centric/people-centric-m.webp"
import PeopleMImg from "../../assets/images/people-centric/people-m.webp"
import ServiceClustersMImg from "../../assets/images/people-centric/service-clusters-m.webp"
const img = "/img/features/BlueMail_Features_Conversation_v2.png"

const PeopleCentric = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="People-Centric | BlueMail App"
        postDescription="Email is all about communication and bringing people together, and with BlueMail’s People Switch, it has never been easier."
        postImage={img}
        postURL="features-functions/people-centric"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid people-centric w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1>
                  <span>People</span> Centric
                </h1>
                <p>
                  Email is all about communication and bringing people together,
                  and with BlueMail’s People Switch, it has never been easier.
                  People Switch is an innovative way of aggregating emails from
                  the same senders together, let it be individuals, shared
                  groups or clustered services. For each person, group or
                  service, BlueMail clusters emails to a single entity, making
                  your email inbox slender and orderly.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={PeopleCentricMImg} alt="People centric" />
            </div>
          </div>
        </div>
        <div className="container-fluid people w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  <span>People</span>
                </h2>
                <p>
                  Conversations with people be it 1:1 or a list of contacts you
                  regularly communicate with.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={PeopleMImg} alt="People" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  <span>Groups</span>
                </h2>
                <p>
                  Group Mail natively sync across all your devices wherever you
                  use BlueMail (appearing right below your Folder List),
                  enabling you to always have your groups available at the tips
                  of your fingers.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img className="d-992" src={GroupsImg} alt="Groups" />
              <img
                className="w-991 mt-3 mb-5"
                src={GroupsMImg}
                alt="Groups mobile"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid service-clusters w-707 r mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h2>
                  Service <span>Clusters</span>
                </h2>
                <p>
                  Automated emails from machines, services and newsletter
                  accessible for you. With a flip of a switch, all services are
                  hidden from the mail list leaving only emails from People or
                  Groups.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={ServiceClustersMImg} alt="Service clusters" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default PeopleCentric

export const query = graphql`
  query PeopleCentricPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
